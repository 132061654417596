import React from 'react';
import { Alert, Row, Col } from 'react-bootstrap';
 
const SuccessAlert = ({ show, message }) => {
  if (!show) return null;
 
  return (
    <Row className='mt-3 ps-3 pe-3'>
      <Col>
        <Alert variant='success'>
        <h1>Success!</h1>
        <p>{message}</p>
        </Alert>
      </Col>
    </Row>
  );
};
 
export default SuccessAlert;